import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";

import {
    fetchMissingTextById,
    getLanguage,
    initializeTextStore,
    updateLanguage
} from "../../helper/util";
import * as utils from "./utils";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import DropdownItem from "../dropdownMenu/DropdownItem";
import TranslatorModal from "../Translator/TextTranslator";
import styleToolbar from "../editText/editorToolbar.module.scss";
import {useServerData} from "../../state/serverDataContext";

let Editor = null;
if (typeof window !== "undefined") {
    Editor = require("../editText/Editor").default;
}

const TextStoreHTMLEditor = (props) => {

    const {modalOpen, id, onClose, i18n, namespace, textToShow} = props;
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImagePreview, setSelectedImagePreview] = useState(null);
    const [isEditState, setIsEditState] = useState(false);
    const [isEditSource, setIsEditSource] = useState(false);
    const [isTranslatorOpen, setIsTranslatorOpen] = useState(false);
    const [targetLanguage, setTargetLanguage] = useState({});
    const [language, setLanguage] = useState(getLanguage(i18n.language));
    const [activeLanguageOptions, setActiveLanguageOptions] = useState([]);
    const [_textToShow, setTextToShow] = useState(textToShow);
    const [textStore, setTextStore] = useState(useServerData());


    useEffect(() => {
        initializeTextStore(textStore,id,namespace,setTextStore,setActiveLanguageOptions,setTargetLanguage,i18n);
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setTextToShow(text[id][i18n.language]);
            }
        });
    }, []);

    useEffect(() => {
        updateLanguage(textStore,id,language.languageCode,setTextToShow);
    }, [language]);

    return (
        <Modal
            open={modalOpen}>
            <Modal.Header
                className={'margin-auto admin-primary'}>{i18n.t("common:common.edit")}</Modal.Header>
            <Modal.Content scrolling className={'margin-auto admin-primary'}>
                {
                    isEditState ?
                        [
                            <div>
                                {
                                    activeLanguageOptions.length > 1 ?
                                        <Button className={"admin-button-basic"}
                                                style={{marginBottom: "10px"}}
                                                onClick={() => {
                                                    setIsTranslatorOpen(true);
                                                }}>
                                            {i18n.t("edit:translate.translator")}
                                        </Button>
                                        : null
                                }

                                <Form>
                                    <Form.Field
                                        style={{display: "inline-flex"}}>
                                        <label
                                            style={{margin: "auto", paddingLeft: "5px"}}>
                                            Edit Source as HTML
                                        </label>
                                        <Form.Radio toggle={true}
                                                    checked={isEditSource}
                                                    onChange={() => {
                                                        setIsEditSource(!isEditSource);
                                                        if (isEditSource) {
                                                            setTextToShow({
                                                                title: '',
                                                                description: '',
                                                                content: _textToShow.content,
                                                                isNamespace: namespace
                                                            })
                                                        }
                                                    }}>
                                            HTML
                                        </Form.Radio>
                                    </Form.Field>
                                    <div style={{margin: "5px"}}/>
                                    <Form.Field className={"background-primary admin-primary"}>
                                        {
                                            !isEditSource ?
                                                Editor &&
                                                <Editor className={"background-primary admin-primary"}
                                                        text={_textToShow.content}
                                                        format={"html"}
                                                        updateText={(editorText) => {
                                                            setTextToShow({
                                                                title: '',
                                                                description: '',
                                                                content: editorText,
                                                                isNamespace: namespace
                                                            });
                                                        }}/>
                                                : <Form.Field>
                                            <textarea className={styleToolbar.editSourceTextarea}
                                                      value={_textToShow.content}
                                                      onChange={e => {
                                                          setTextToShow({
                                                              title: '',
                                                              description: '',
                                                              content: e.target.value,
                                                              isNamespace: namespace
                                                          });
                                                      }}
                                            />
                                                </Form.Field>
                                        }
                                    </Form.Field>
                                    <Form.Field>
                                        <Button.Group>
                                            <Button className={"admin-button-primary"}
                                                    onClick={() => {
                                                        utils.handleSave(_textToShow,
                                                            selectedImage,
                                                            selectedImagePreview,
                                                            id,
                                                            language.languageCode,
                                                            textStore,
                                                            namespace,
                                                            setIsEditState,
                                                            setSelectedImage,
                                                            setSelectedImagePreview,
                                                            i18n,
                                                            onClose);
                                                    }}>
                                                {i18n.t("common:common.save")}
                                            </Button>
                                            <Button className={"admin-button-warn"}
                                                    onClick={() => {
                                                        if (isEditSource) {
                                                            setIsEditSource(false);
                                                        } else {
                                                            setIsEditState(false);
                                                        }
                                                    }}>
                                                {i18n.t("common:common.cancel")}
                                            </Button>
                                        </Button.Group>
                                    </Form.Field>
                                </Form>
                            </div>,
                            <TranslatorModal
                                isTranslatorOpen={isTranslatorOpen}
                                setIsTranslatorOpen={setIsTranslatorOpen}
                                setTargetLanguage={setTargetLanguage}
                                targetLanguage={targetLanguage}
                                language={language}
                                textStore={textStore}
                                editType={'html'}
                                activeLanguageOptions={activeLanguageOptions}
                                setIsEditState={setIsEditState}
                                id={id}
                                onClose={(text) => {
                                    setTextToShow({...textStore, content: text});
                                }}
                            />
                        ]
                        : (
                            <>
                                {
                                    activeLanguageOptions.length >= 1 ?
                                        <div style={{display: "inline-flex"}}>
                                            <label
                                                style={{
                                                    margin: "auto",
                                                    marginRight: "5px"
                                                }}>
                                                <b>{i18n.t("common:common.language")}:</b>
                                            </label>
                                            <p style={{margin: "auto", marginRight: "5px"}}>{language.label}</p>
                                            <DropdownMenu name={"Select Language"}
                                                          selector={true}
                                                          defaultValue={language.label}
                                                          onChange={(selection) => {
                                                              setLanguage(getLanguage(activeLanguageOptions.find(langToSet => langToSet.label === selection).value));
                                                          }}>
                                                {
                                                    activeLanguageOptions.map((language, index) => (
                                                        <DropdownItem key={index}>{language.label}</DropdownItem>
                                                    ))
                                                }
                                            </DropdownMenu>
                                            <Divider/>
                                        </div>
                                        : null
                                }
                                <div style={{marginTop: '10px', marginBottom: '10px'}}
                                     id={id}
                                     dangerouslySetInnerHTML={{__html: typeof _textToShow !== "undefined" ? _textToShow.content : null}}/>
                                <Button
                                    className={'ui button circular admin-button-primary'}
                                    icon="edit outline"
                                    disabled={typeof language === "string"}
                                    onClick={() => {
                                        setIsEditState(true);
                                    }}/>
                                <Button
                                    floated="right"
                                    className={"admin-button-warn"}
                                    onClick={() => onClose("")}>
                                    {i18n.t("common:common.cancel")}
                                </Button>
                            </>
                        )
                }
            </Modal.Content>
        </Modal>
    );
}

TextStoreHTMLEditor.propTypes =
    {
        id: PropTypes.string,
        modalOpen: PropTypes.bool,
        onClose: PropTypes.func,
        namespace: PropTypes.string
    };

TextStoreHTMLEditor.defaultProps =
    {
        id: Math.random().toString(),
        modalOpen: false,
        namespace: "",
        onClose: () => {
        }
    };

export default withTranslation(['edit', 'common'])(TextStoreHTMLEditor);